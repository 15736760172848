import { useState, useEffect } from 'react';
import moment from 'moment';
import { useCountdown } from '../UI/BuButtonRefresh/useCountdown';

interface UseRefreshProps {
  onClick: () => void;
  cacheDate?: string;
  isLoading?: boolean;
  disabled?: boolean;
  targetDate?: number;
  warningElapsedTimeSeconds?: number;
  testingLabel?: string;
}

interface UseRefreshReturn {
  buttonProps: {
    onClick: () => void;
    disabled: boolean;
    'data-testing': string;
  };
  isLoading: boolean;
  lastUpdatedText: string;
  /**
   * Data testing attribute for the last update message
   */
  lastUpdatedTestingKey: string;
  /**
   * Indicates if the data is considered stale based on warningElapsedTimeSeconds.
   * Used to show visual feedback when data needs refreshing.
   */
  showWarning: boolean;
  countdownLabel: string | null;
  showCountdown: boolean;
}

export const useRefreshData = ({
  onClick,
  cacheDate,
  isLoading = false,
  disabled = false,
  targetDate,
  warningElapsedTimeSeconds,
  testingLabel = '',
}: UseRefreshProps): UseRefreshReturn => {
  const [canRefresh, setCanRefresh] = useState(true);
  const [showCountdown, setShowCountdown] = useState(false);

  const [_, __, ___, seconds, targetIsGone] = useCountdown(targetDate || 0);

  useEffect(() => {
    if (targetDate && targetDate > Date.now()) {
      setShowCountdown(true);
      setCanRefresh(false);
    }

    if (targetIsGone) {
      setShowCountdown(false);
      setCanRefresh(true);
    }
  }, [targetDate, targetIsGone]);

  const getLastUpdatedText = () => {
    if (!cacheDate) return '';

    const now = moment();
    const since = moment(cacheDate);
    const isSameDay = now.isSame(since, 'day');
    const sinceDay = !isSameDay ? `on ${since.format('MMM DD')}` : 'Today';
    const time = since.format('h.mmA').toLowerCase();

    return `Updated ${sinceDay} at ${time}`;
  };

  const showWarning =
    warningElapsedTimeSeconds && cacheDate
      ? moment().diff(moment(cacheDate), 'seconds') > warningElapsedTimeSeconds
      : false;

  const componentTestingKey = testingLabel
    ? `${testingLabel}-RefreshAction`
    : 'RefreshAction';

  const getCountdownLabel = (): string | null => {
    if (!showCountdown) return null;
    return `Please wait ${seconds} sec`;
  };

  return {
    buttonProps: {
      onClick,
      disabled: disabled || !canRefresh,
      'data-testing': `${componentTestingKey}-Btn`,
    },
    isLoading,
    lastUpdatedText: getLastUpdatedText(),
    lastUpdatedTestingKey: `${componentTestingKey}-Date`,
    showWarning,
    countdownLabel: getCountdownLabel(),
    showCountdown,
  };
};
