import { css } from 'emotion';

export const totalItemPopup = css`
  &.ui.popup {
    max-width: 480px;
  }
`;

export const containerTileWithoutValue = css`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 0;
`;

export const actionableTitleStyle = css`
  color: var(--bu-gray-700);
  font-size: 12px;
  margin: 0px;
`;

export const readOnlyTotalsTitleStyle = css`
  font-family: var(--bu-font-regular);
  cursor: default;
  font-size: 10px;
  color: var(--bu-gray-700);
  margin: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

export const readOnlyTotalValueContainer = css`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 4px 8px;
  gap: 4px;
`;

export const actionableTotalValueContainer = css`
  display: flex;
  flex-direction: column;

  width: 90%;
  margin: 4px 8px;
`;

export const metricNameParagraph = css`
  padding-left: 8px;
  font-size: 16px;
`;

export const valueStyle = css`
  font-family: var(--bu-font-regular);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
`;

export const totalItemContainer = css`
  max-width: 100%;
`;
