import styled from '@emotion/styled';
import * as R from 'ramda';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { useQuery } from '@tanstack/react-query';
import { getTableData } from 'api/ReportTable';
import { TABLE_ID } from 'common/constants';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import Table from 'components/UI/TableConfig/Table';
import {
  columnTypes,
  ColumnTypesCallback,
  handleColumnClick,
} from 'components/UI/TableConfig/column-helper';
import {
  isColumnConfigClickable,
  TableConfigurationData,
} from 'components/UI/TableConfig/types';
import {
  IColumn,
  IRow,
  SortOrder,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { isMetricFiltersValid } from 'components/dashboard/Metrics/Create/utils';
import { metricObjectToPlural } from 'components/dashboard/Metrics/Widget/Report/helpers';
import { LoaderContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import { ACCOUNT, OPPORTUNITY } from 'components/dashboard/Metrics/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { parseFilters } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIDashboardSettings,
  BIMetricsFilter,
  BIMetricsQueryFilter,
  BIWidget,
  DataDescriptor,
  ExternalColumnFieldFilter,
} from 'components/dashboard/Metrics/metrics.types';
import { openModal } from 'navigation/utils';
import * as selectors from 'selectors';
import { getColumnAlignmentByType } from 'utils/aligns';
import { fetchApi, QueryStatus } from 'utils/network';
import { getApiPathAndSearchParam } from './helpers';
import { usePageSize } from 'components/hooks/usePageSize';

const TableContainer = styled.div({
  overflow: 'auto',
  paddingTop: '10px',
});

interface Props {
  dashboardSettings?: BIDashboardSettings;
  widget: Partial<BIWidget>;
  onChangeWidget: (widget: Partial<BIWidget>) => void;
}

export type GetTableDataApiParams = {
  skip_business_validation?: boolean | undefined;
  ids: string[];
  page_number: number;
  page_size: number;
  sort: string | null | undefined;
  opportunity_name?: string;
  account_name?: string;
  dashboard_filters?: BIMetricsFilter[];
};

export const ReportTable: React.FC<Props> = ({
  dashboardSettings,
  widget,
  onChangeWidget,
}) => {
  const { currency: companyCurrencyCode } = useContext(RevBISettingsContext);

  const isMultiCurrencyEnabled = useSelector(selectors.isMulticurrencyEnabled);

  const [drillDownDataStatus, setDrillDownDataStatus] =
    useState<QueryStatus>('notAsked');
  const [drillDownIds, setDrillDownIds] = useState<string[]>([]);
  const [dataIdsCount, setDataIdsCount] = useState<number | null>();
  const [drillDownCount, setDrillDownCount] = useState<number | null>();
  const [pageNumber, setPageNumber] = useState(1);
  const [dataSortOrder, setDataSortOrder] = useState('');
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);

  const [columnList, setColumnList] = useState<DataDescriptor[]>([]);
  const [columnFetchStatus, setColumnFetchStatus] =
    useState<QueryStatus>('notAsked');

  const [drillDownAbortController, setDrillDownAbortController] = useState(
    new AbortController()
  );

  const [searchText, setSearchText] = useState('');

  const metricObject: string = widget.metric_list?.[0]?.object || OPPORTUNITY;

  const metricFilters: BIMetricsQueryFilter[] =
    widget.metric_list?.[0]?.filters ?? [];

  const isMetricObjectEqualToOpportunity = metricObject === OPPORTUNITY;

  const [pageSize] = usePageSize(
    isMetricObjectEqualToOpportunity ? 'Opportunities' : 'Accounts'
  );

  const handleOpportunitiesTableSpecialClick: ColumnTypesCallback = (props) => {
    if (props.column.type !== ColumnTypes.SALES_PROCESS) {
      handleColumnClick(props);
    }
  };

  const handleAccountsTableSpecialClick: ColumnTypesCallback = ({
    column,
    columnConfig,
    objectId,
  }) => {
    if (column.type === ColumnTypes.TEXT) {
      if (isColumnConfigClickable(columnConfig)) {
        switch (columnConfig.meta.object_id) {
          case 'account_id':
          case '_id':
            openModal({
              scheme: '/account/:id',
              params: {
                id: objectId,
              },
            });
            break;
          default:
            console.warn('Action not supported');
        }
      } else {
        console.warn('Action not supported');
      }
    } else if (column.type === ColumnTypes.SCORE) {
      openModal({
        scheme: '/account/:id',
        params: {
          id: objectId,
        },
      });
    }
  };

  const getColumnType = columnTypes(
    {
      currencyFormatter: currencyFormatter(
        companyCurrencyCode,
        0,
        isMultiCurrencyEnabled
      ),
      sequence: [],
    },
    isMetricObjectEqualToOpportunity
      ? handleOpportunitiesTableSpecialClick
      : handleAccountsTableSpecialClick,
    '',
    isMultiCurrencyEnabled
  );

  // Fetch column fields filters
  useEffect(() => {
    const abortController = new AbortController();

    fetchApi<ExternalColumnFieldFilter, DataDescriptor[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter`,
      queryMethod: 'post',
      queryParams: {
        tables: [metricObject],
      },
      setData: (fields: DataDescriptor[]) => {
        setColumnList(parseFilters(fields));
      },
      setError: (error: string | null) => {
        setColumnList([]);
        toast.error(`Failed to load column fields filters: ${error}`);
      },
      setStatus: setColumnFetchStatus,
      signal: abortController.signal,
    });

    return () => {
      setColumnList([]);
      abortController.abort();
    };
  }, [metricObject]);

  // Fetch table columns
  useEffect(() => {
    const objectApiPath = metricObject
      ? isMetricObjectEqualToOpportunity
        ? 'opportunity/Opportunities'
        : 'account/Accounts'
      : '';

    if (objectApiPath && columnFetchStatus === 'success') {
      fetchApi<void, { data: TableConfigurationData }>({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/tables/${objectApiPath}`,
        queryMethod: 'get',
        setData: (result) => {
          const data = result ?? {};
          const cols = data.data.columns;

          if (!widget?.order_by_column?.name) {
            const defaultOrder = data.data.order;
            const column = columnList?.find(
              (c) => c.name === defaultOrder.object_field
            );

            if (column) {
              onChangeWidget({ order_by_column: column });
            }
          }

          // RC-337: Force money type for number type on RevBI
          const getColumnTypeAlignment = (type: string) =>
            type === 'number' ? 'money' : type;

          const newColumns =
            cols
              ?.map<IColumn>((column, index) => {
                const columnTypeConfig = getColumnType(column, cols);
                const columnAlign = getColumnAlignmentByType(
                  column.type == 'custom'
                    ? column.meta?.type
                    : getColumnTypeAlignment(column.type),
                  columnTypeConfig.align
                );

                return {
                  field: column.object_field,
                  sort_order: SortOrder.DESCENDING,
                  sortable: column.sortable,
                  id: `${column.field_name}-${index}`,
                  label: column.display_name,
                  ...columnTypeConfig,
                  editable: false,
                  align: columnAlign,
                };
              })
              .map((column) => ({
                ...column,
                showTooltip: ![
                  ColumnTypes.NUMBER,
                  ColumnTypes.PERCENT,
                  ColumnTypes.MONEY,
                ].includes(column.type),
              })) || [];

          setColumns(newColumns);
        },
        setError: (_: string | null) => {
          toast.error('Failed to load table columns');
          setDrillDownDataStatus('error');
        },
      });
    }
  }, [metricObject, columnFetchStatus]);

  const [getTableDataParams, tableDataUrlApiPath] = useMemo(() => {
    const [dataApiPath, searchParam] = getApiPathAndSearchParam(
      metricObject,
      isMetricObjectEqualToOpportunity
    );

    const dashboardFilters =
      widget.dashboard_filters?.filter((elem) => elem.operator !== 'all') ?? [];
    const apiParams: GetTableDataApiParams = {
      ids: dataSortOrder
        ? drillDownIds
        : drillDownIds.slice(
            pageSize * (pageNumber - 1),
            pageSize * pageNumber
          ),
      page_number: dataSortOrder ? pageNumber - 1 : 0,
      page_size: pageSize,
      sort: dataSortOrder || null,
      dashboard_filters: dashboardFilters,
      [searchParam]: searchText || undefined,
      ...(isMetricObjectEqualToOpportunity && {
        skip_business_validation: true,
      }),
    };

    return [apiParams, dataApiPath];
  }, [
    dataSortOrder,
    pageNumber,
    drillDownDataStatus,
    searchText,
    columnFetchStatus,
    metricObject,
  ]);

  const { isFetching: isFetchingTableData, data: tableData } = useQuery({
    queryKey: [
      'get_report_table_data',
      getTableDataParams,
      tableDataUrlApiPath,
    ],
    queryFn: () => getTableData({ getTableDataParams, tableDataUrlApiPath }),
    enabled:
      drillDownDataStatus === 'success' &&
      columnFetchStatus === 'success' &&
      drillDownIds.length > 0 &&
      !!tableDataUrlApiPath,
  });

  useEffect(() => {
    if (!isFetchingTableData && tableData) {
      let parsedDeals: IRow[] = [];

      if (isMetricObjectEqualToOpportunity) {
        parsedDeals = tableData.data?.deals.map((deal) => ({
          ...deal,
          id: `${deal._id}`,
          crm_metadata:
            deal.crm_metadata && deal.crm_metadata.next_step === null
              ? { ...deal.crm_metadata, next_step: '' }
              : deal.crm_metadata,
        }));
      }

      if (metricObject === ACCOUNT) {
        parsedDeals = tableData.accounts.map((account) => ({
          ...account,
          id: `${account._id}`,
        }));
      }

      let dataIdsCount = drillDownCount || 0;

      /**
       * If it is being filtered by text, table totalCount should be the
       * count on the response. If not, show the total IDs count
       */
      if (searchText) {
        dataIdsCount = tableData.total_count || tableData.data.count;
      }

      setRows(parsedDeals);
      setDataIdsCount(dataIdsCount);
    } else {
      setRows([]);
      setDataIdsCount(0);
    }
  }, [tableData, isFetchingTableData]);

  useEffect(() => {
    // When request drilldown ids we have to update the count of the ids
    setDataIdsCount(drillDownCount);
  }, [drillDownCount]);

  // Fetch table data
  useEffect(() => {
    const dashboardFilters =
      widget.dashboard_filters?.filter((elem) => elem.operator !== 'all') ?? [];
    const queryParams = {
      filters: metricFilters ?? [],
      widget_filters: widget.widget_filters ?? [],
      template_filters: widget.template_filters ?? [],
      dashboard_filters: dashboardFilters,
      order_by: widget.order_by_column?.name
        ? [
            {
              asc: widget.order_by?.[0] === 'ascending',
              column: widget.order_by_column,
            },
          ]
        : [],
      limit: !widget?.limit || widget?.limit === 0 ? 0 : widget?.limit,
      load_extras: true,
    };

    if (
      queryParams.order_by &&
      (!queryParams.filters.length ||
        isMetricFiltersValid(widget.metric_list?.[0]))
    ) {
      // Before get the new data we have to reset all the table
      moveToFirstPageNumber();
      setDataSortOrder('');
      setSearchText('');
      setRows([]);

      setDrillDownDataStatus('loading');
      let abort = drillDownAbortController;
      if (drillDownDataStatus === 'loading') {
        abort.abort();
        abort = new AbortController();
        setDrillDownAbortController(abort);
      }
      //this is the first dashboard setting, but in case we add one more, we should rethink this.
      //?user_status=active
      let querySetting = '';
      if (dashboardSettings) {
        querySetting = `?user_status=${dashboardSettings.userFilter}`;
      }
      fetchApi<any, { data: Array<string>; count?: number }>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${metricObject}${querySetting}`,
        queryMethod: 'post',
        queryParams: queryParams,
        setData: (result) => {
          const data = result.data ?? [];
          const widgetLimit = widget?.limit || 0;
          const resultCount = result.count || 0;

          /**
           * If user set a num of record AND it is not bigger than res count,
           * we use it. If not we use the res count
           */
          if (
            !isNaN(widgetLimit as number) &&
            widgetLimit !== 0 &&
            widgetLimit < resultCount
          ) {
            setDrillDownCount(widgetLimit);
          } else if (resultCount) {
            setDrillDownCount(resultCount);
          }

          setDrillDownIds(data);

          setDrillDownDataStatus('success');
        },
        setError: (_: string | null) => {
          toast.error('Failed to load table data');
          setDrillDownDataStatus('error');
        },
        signal: abort.signal,
      });
    }
  }, [
    JSON.stringify(metricFilters),
    widget?.limit,
    widget?.order_by_column,
    widget?.order_by,
    JSON.stringify(widget?.template_filters),
    JSON.stringify(widget?.dashboard_filters),
    JSON.stringify(dashboardSettings),
  ]);

  const moveToFirstPageNumber = () => {
    setPageNumber(1);
  };

  const handlePaginationChange = (page: number) => {
    setPageNumber(page);
  };

  const handleSort = (sort?: string) => {
    moveToFirstPageNumber();
    setDataSortOrder(sort || '');
  };

  const handleSearchChange = (value: string) => {
    moveToFirstPageNumber();
    setSearchText(value);
  };

  const handleChange = useCallback(
    (column: IColumn, row: IRow, newValue: ValueProp) => {
      const {
        type,
        config: { multiselect },
      } = column;
      let valueUpdated = newValue;

      if (type === 'select' && multiselect) {
        valueUpdated = (newValue as string[]).join(';');
      }

      const newRows = rows.map((item) =>
        item.id === row.id
          ? R.assocPath(column.field.split('.'), newValue, item)
          : item
      );

      const updateQueryParams = {
        [column.field]: valueUpdated,
      };
      setRows(newRows);

      const UPDATE_API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/data/${
        isMetricObjectEqualToOpportunity ? 'deals' : 'accounts'
      }/update/${row.id}/`;

      fetchApi<any, any>({
        url: UPDATE_API_URL,
        queryMethod: 'post',
        queryParams: updateQueryParams,
        setData: (_) => {},
        setError: (_: string | null) => {
          toast.error('Failed to update row!');
          setRows(rows);
        },
      });
    },
    [rows]
  );

  const downloadButton: DownloadButtonProps = useMemo(
    () => ({
      serializedQueryParams: JSON.stringify({
        page_number: 0,
        page_size: dataIdsCount,
        ids: drillDownIds,
      }),
      queryMethod: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/data/${
        isMetricObjectEqualToOpportunity ? 'deals' : 'accounts'
      }/csv`,
    }),
    [drillDownIds, metricObject]
  );

  return (
    <>
      {drillDownDataStatus === 'loading' && (
        <LoaderContainer>
          <Loader active content="Loading" />
        </LoaderContainer>
      )}

      {drillDownDataStatus === 'success' && (
        <TableContainer>
          <Table
            tableId={`${TABLE_ID.REVBI_REPORT}-${widget.id || widget._id}`}
            pinnableColumns
            hidePaginationEnd
            fullscreen
            loading={isFetchingTableData || columnFetchStatus === 'loading'}
            showColumnsVisibilityToggle
            title={`Showing ${metricObjectToPlural(metricObject || '')}`}
            totalCount={dataIdsCount || 0}
            currentPage={pageNumber}
            rowsPerPage={pageSize}
            downloadButton={downloadButton}
            data={rows}
            columns={columns}
            sortOrder={dataSortOrder}
            onSort={handleSort}
            onSearchChange={handleSearchChange}
            onChange={handleChange}
            onPaginationChange={handlePaginationChange}
            styleFirstColumn
            firstColumnSize={400}
          />
        </TableContainer>
      )}
    </>
  );
};
