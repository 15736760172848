import classNames from 'classnames';
import {
  moreMargin,
  readOnlyTotalCardItem,
  readOnlyTotalContainer,
} from './styles';

interface ReadOnlyTotalsProps {
  children: React.ReactNode;
  hasChart: boolean;
}

export const ReadOnlyTotals = ({ children, hasChart }: ReadOnlyTotalsProps) => {
  return (
    <div
      className={classNames([
        readOnlyTotalContainer,
        { [moreMargin]: hasChart },
      ])}
      data-testing="totals-container"
    >
      {children}
    </div>
  );
};

interface LayoutReadOnlyTotlItemProps {
  children: React.ReactNode;
  isClickable: boolean;
  ['data-testing']: string;
  handleClick: () => void;
}
export const LayoutReadOnlyTotalItem = ({
  children,
  isClickable,
  'data-testing': dataTesting,
  handleClick,
}: LayoutReadOnlyTotlItemProps) => {
  return (
    <div
      data-testing={dataTesting}
      className={classNames([
        readOnlyTotalCardItem,
        {
          clickable: isClickable,
        },
      ])}
      onClick={isClickable ? handleClick : () => {}}
    >
      {children}
    </div>
  );
};
