import { IColumn } from 'components/UI/common/TypedTable/TypedTable';

export const FIRST_COLUMN_DEFAULT_SIZE = 250;

export const isActionsColumn = (column: IColumn | undefined): boolean =>
  !!(
    column &&
    (column.id === 'actions' ||
      (column.config.className && column.config.className === 'actionColumn'))
  );

export const isPrimaryColumn = (column: IColumn | undefined): boolean =>
  !!(
    column &&
    column.config.className &&
    column.config.className === 'primary-cell'
  );
