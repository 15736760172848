import styled from '@emotion/styled';
import { css } from 'emotion';

interface WidgetTitleRowProps {
  readonly isDashboardModal: boolean;
  readonly isDashboardWidget: boolean;
}

export const WidgetTitleRow = styled.div(
  ({
    isDashboardModal = false,
    isDashboardWidget = false,
  }: WidgetTitleRowProps) => ({
    display: 'flex',
    alignItems: 'center',
    maxHeight: '50px',
    h4: {
      fontSize: '14px',
      fontWeight: 600,
      margin: '0px',
      fontFamily: 'var(--bu-font-semi-bold)',
      color: 'var(--bu-gray-900)',
    },
    h5: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      margin: '0px',
      textTransform: 'uppercase',
      color: 'var(--bu-gray-700)',
      fontWeight: 400,
      gap: '4px',

      i: {
        fontSize: '14px',
      },
    },
    i: {
      fontSize: '20px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    padding:
      isDashboardModal || !isDashboardWidget
        ? '16px 16px 12px 16px'
        : '8px 8px 10px 8px',
    justifyContent: 'space-between',
  })
);

export const ActionsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  i: {
    fontSize: '20px !important',
    color: 'var(--bu-gray-800) !important',
  },
});

export const TitleWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  flex: 1,
});

export const MetricsToggleLabel = styled.span({
  color: 'var(--bu-gray-700)',
});

export const titleSection = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 4px;
  min-width: 0;
`;

export const titleRow = css`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
`;

export const cacheLabel = css`
  font-family: var(--bu-font-regular);
  cursor: default;
  font-size: 10px;
  color: var(--bu-gray-700);
  margin: 0;
`;

export const lastUpdatedLabelWithAlert = css`
  color: var(--bu-red-400);
`;

export const refreshButton = css`
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 14px;
  background: transparent;
  border: none;

  &:hover {
    i {
      color: var(--bu-primary-500) !important;
    }
  }

  &.load {
    pointer-events: none;
    i {
      animation: spin 3s linear 0s infinite;
      display: inline-block;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  &:disabled {
    background-color: var(--bu-gray-300);
    pointer-events: none;
  }
`;

export const refreshIcon = css`
  font-size: 16px !important;
`;

export const noTextOverflow = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
