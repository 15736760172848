import { BreadcrumbOptions } from 'highcharts';
import { ManagerActions } from './Actions/ManagerActions';
import { OnVisualizationChange } from './Dropdown/VisualizationDropdown';
import TotalSectionItem from './TotalSectionItem';
import {
  LayoutReadOnlyTotalItem,
  ReadOnlyTotals,
} from './TotalSectionLayout/ReadOnlyTotals';
import {
  LayoutActionableTotals,
  LayoutActionableTotalsItem,
} from './TotalSectionLayout/ActionableTotals';
import {
  ManagerActionsConfig,
  TotalSectionConfig,
  TotalSectionLayoutType,
} from './helper';
import {
  actionableTitleStyle,
  actionableTotalValueContainer,
  readOnlyTotalValueContainer,
  readOnlyTotalsTitleStyle,
} from './styles';

interface SectionWrapperProps {
  children: React.ReactNode;
  /**
   * Manager actions to be displayed in the section
   */
  managerActions?: React.ReactNode;
  layoutType: TotalSectionLayoutType;
  hasChart: boolean;
}

const SectionWrapper = ({
  children,
  layoutType,
  managerActions,
  hasChart,
}: SectionWrapperProps) => {
  switch (layoutType) {
    case 'actionable':
      return (
        <LayoutActionableTotals managerActions={managerActions}>
          {children}
        </LayoutActionableTotals>
      );
    case 'readonly':
      return <ReadOnlyTotals hasChart={hasChart}>{children}</ReadOnlyTotals>;
  }
};

interface ItemWrapperProps {
  layoutType: TotalSectionLayoutType;
  children: React.ReactNode;
  metricLength: number;
  isClickable: boolean;
  'data-testing': string;
  onClick: () => void;
}

const ItemWrapper = ({
  children,
  layoutType,
  metricLength,
  onClick,
  'data-testing': dataTesting,
  isClickable,
}: ItemWrapperProps) => {
  switch (layoutType) {
    case 'actionable':
      return (
        <LayoutActionableTotalsItem
          data-testing={dataTesting}
          metricLength={metricLength}
          handleClick={onClick}
          isClickable={isClickable}
        >
          {children}
        </LayoutActionableTotalsItem>
      );
    case 'readonly':
      return (
        <LayoutReadOnlyTotalItem
          data-testing={dataTesting}
          handleClick={onClick}
          isClickable={isClickable}
        >
          {children}
        </LayoutReadOnlyTotalItem>
      );
  }
};

export type OnTotalClick = (metricId: string, chartLevel?: any) => void;

interface TotalSectionProps {
  totalPanelConfig: TotalSectionConfig;
  chartLevels: BreadcrumbOptions[];
  managerActionsConfig: ManagerActionsConfig;
  onVisualizationChange: OnVisualizationChange;
  onTotalClick: OnTotalClick;
  onComputeUserHierarchyResponseChange: (compute: boolean) => void;
  onRemoveMyReporteesDataChange: (show: boolean) => void;
}

const layoutToValueContainerStyle: Record<TotalSectionLayoutType, string> = {
  actionable: actionableTotalValueContainer,
  readonly: readOnlyTotalValueContainer,
};

const layoutToTitleStyle: Record<TotalSectionLayoutType, string> = {
  actionable: actionableTitleStyle,
  readonly: readOnlyTotalsTitleStyle,
};

const layoutToPopupPosition: Record<
  TotalSectionLayoutType,
  'bottom left' | 'bottom center'
> = {
  actionable: 'bottom left',
  readonly: 'bottom left',
};

export const TotalsSection = ({
  totalPanelConfig,
  chartLevels,
  managerActionsConfig,
  onVisualizationChange,
  onTotalClick,
  onComputeUserHierarchyResponseChange,
  onRemoveMyReporteesDataChange,
}: TotalSectionProps) => {
  const layoutType = totalPanelConfig.layoutType;
  const totals = totalPanelConfig.totals;

  const managerActionsComponent = managerActionsConfig.enabled ? (
    <ManagerActions
      computeUserHierarchyResponse={
        managerActionsConfig.computeUserHierarchyResponse
      }
      removeMyReporteesData={managerActionsConfig.removeMyReporteesData}
      onComputeUserHierarchyResponseChange={
        onComputeUserHierarchyResponseChange
      }
      onRemoveMyReporteesDataChange={onRemoveMyReporteesDataChange}
      data-testing="widget-manager-actions"
    />
  ) : null;

  return (
    <SectionWrapper
      layoutType={layoutType}
      managerActions={managerActionsComponent}
      hasChart={totalPanelConfig.hasChart}
    >
      {totals.map((total, index) => {
        const titleStyle = layoutToTitleStyle[layoutType];
        const containerStyle = layoutToValueContainerStyle[layoutType];
        const popupPosition = layoutToPopupPosition[layoutType];
        return (
          <ItemWrapper
            key={total.id}
            data-testing={`total-${index}`}
            layoutType={layoutType}
            metricLength={totals.length}
            isClickable={total.isClickable}
            onClick={() => {
              if (chartLevels.length) {
                onTotalClick(total.id, chartLevels[chartLevels.length - 1]);
              }
              onTotalClick(total.id);
            }}
          >
            <TotalSectionItem
              key={total.id}
              total={total}
              popupPosition={popupPosition}
              containerStyle={containerStyle}
              titleStyle={titleStyle}
              onVisualizationChange={onVisualizationChange}
            />
          </ItemWrapper>
        );
      })}
    </SectionWrapper>
  );
};
