import { css } from 'emotion';

export const tooltipStyles = css`
  font-family: var(--bu-font-semi-bold);
  font-weight: 600;
  font-size: 12px;
  color: var(--bu-gray-900);

  .title {
    margin-bottom: 6px;
  }
`;
