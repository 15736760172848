import classNames from 'classnames';
import {
  actionableTotalsContainer,
  actionableTotalsInnerContainer,
  actionableTotalsItemContainer,
  actionableTotalsValueContainer,
  totalCardAThirdOfSpace,
  totalCardHalfSpace,
} from './styles';

interface LayoutActionableTotalsProps {
  children: React.ReactNode;
  managerActions?: React.ReactNode;
}

export const LayoutActionableTotals = ({
  children,
  managerActions,
}: LayoutActionableTotalsProps) => (
  <div className={actionableTotalsContainer}>
    <div
      className={actionableTotalsInnerContainer}
      data-testing="totals-container"
    >
      <div
        className={actionableTotalsValueContainer}
        data-testing="totals-block-section"
      >
        {children}
      </div>
    </div>
    {managerActions}
  </div>
);

interface LayoutActionableTotalsItemProps {
  children: React.ReactNode;
  isClickable: boolean;
  metricLength: number;
  'data-testing': string;
  handleClick: () => void;
}
export const LayoutActionableTotalsItem = ({
  children,
  isClickable,
  metricLength,
  'data-testing': dataTesting,
  handleClick,
}: LayoutActionableTotalsItemProps) => {
  const shouldItemUseAThirdOfSpace: boolean = metricLength % 2 !== 0;
  return (
    <div
      data-testing={dataTesting}
      className={classNames([
        actionableTotalsItemContainer,
        {
          clickable: isClickable,
        },
        shouldItemUseAThirdOfSpace
          ? totalCardAThirdOfSpace
          : totalCardHalfSpace,
      ])}
      onClick={isClickable ? handleClick : () => {}}
    >
      {children}
    </div>
  );
};
