import classNames from 'classnames';
import { css } from 'emotion';
import Highcharts from 'highcharts';

const roundedCorners = css`
  border-right: 1em;
`;

type TargetConfig = {
  label: string;
  name: string;
  value: number;
  visible: boolean;
  color: string;
  dashStyle: Highcharts.PlotLineOptions['dashStyle'];
};

export const addTargetLine = (
  options: Highcharts.Options,
  target: TargetConfig
): Highcharts.Options => {
  const initOptions = (options: Highcharts.Options): Highcharts.Options => {
    if (!options.chart) {
      options.chart = {};
    }
    options.chart.className += classNames(
      options.chart.className,
      roundedCorners
    );

    if (!options.xAxis) {
      options.xAxis = [];
    } else if (!Array.isArray(options.xAxis)) {
      options.xAxis = [options.xAxis];
    }

    if (!options.series) {
      options.series = [];
    } else if (!Array.isArray(options.series)) {
      options.series = [options.series];
    }

    const yAxis = (Array.isArray(options.yAxis)
      ? options.yAxis[0]
      : options.yAxis) || {
      title: { text: '' },
    };

    if (!yAxis.plotLines) {
      yAxis.plotLines = [];
    }

    if (!options.yAxis || !Array.isArray(options.yAxis)) {
      options.yAxis = [yAxis];
    }

    return options;
  };

  const getLabelWidth = (label: string) => {
    const span = document.createElement('span');
    span.innerText = label;
    document.body.appendChild(span);
    const estimatedLabelWidth = span.offsetWidth;
    document.body.removeChild(span);

    return estimatedLabelWidth;
  };

  const getTargetPlotLine = (
    target: TargetConfig,
    paddingLeftRight: number
  ): Highcharts.YAxisPlotLinesOptions => ({
    value: target.value,
    label: {
      text: target.label,
      align: 'left',
      verticalAlign: 'middle',
      x: -(getLabelWidth(target.label) + paddingLeftRight * 2),
      y: 0,
      useHTML: true,
      style: {
        padding: `2px ${paddingLeftRight}px`,
        backgroundColor: target.color,
        color: '#fff',
        fontFamily: '',
        fontSize: '',
      },
    },
    dashStyle: target.dashStyle,
    color: 'none',
    width: 3,
    acrossPanes: true,
  });

  const getTargetSeries = (
    options: Highcharts.Options,
    xAxisTarget: Highcharts.XAxisOptions,
    target: TargetConfig
  ): Highcharts.SeriesLineOptions => ({
    type: 'line',
    name: target.name,
    visible: target.visible,
    color: target.color,
    marker: {
      lineWidth: 3,
      lineColor: target.color,
      fillColor: 'white',
      symbol: 'circle',
      enabled: false,
    },
    xAxis: (options.xAxis as Highcharts.XAxisOptions[]).length - 1,
    dashStyle: target.dashStyle,
    data: [
      {
        y: target.value,
        x: -0.5,
        marker: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      ...xAxisTarget.categories!.map((_, x) => ({ y: target.value, x })),
      {
        y: target.value,
        x: Math.max(xAxisTarget.categories!.length - 1, 0) + 0.5,
        marker: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    ],
  });

  const getXAxis = (
    options: Highcharts.Options,
    cloneCategories: boolean = false
  ): Highcharts.XAxisOptions => {
    return {
      type: 'linear',
      categories: cloneCategories
        ? (options.xAxis as Highcharts.XAxisOptions[])[0]?.categories || ['']
        : [],
      minPadding: 0,
      maxPadding: 0,
      min: 0,
      max: cloneCategories
        ? ((options.xAxis as Highcharts.XAxisOptions[])[0]?.categories
            ?.length || 1) - 1
        : 0,
      visible: false,
    };
  };

  options = initOptions(options);

  const xAxisTarget = getXAxis(options, true);

  (options.xAxis as Highcharts.XAxisOptions[]).push(xAxisTarget);

  if (target.visible) {
    (options.yAxis as Highcharts.YAxisOptions[])[0]!.plotLines!.push(
      getTargetPlotLine(target, 9)
    );
  }

  options.series!.push(getTargetSeries(options, xAxisTarget, target));

  return options;
};

export type LabelMapped = {
  label: string;
  color: string;
};
export class HighchartsColors {
  static lastAssigned = 0;
  static colorAssigned: LabelMapped[] = [];
  static colorsMapped: LabelMapped[] = [
    {
      label: 'forecast target',
      color: 'var(--bu-color-constant-forecast-target)',
    },
    {
      label: 'pipeline creation target',
      color: 'var(--bu-color-constant-pipeline-creation-target)',
    },
    {
      label: 'pipeline coverage target',
      color: 'var(--bu-color-constant-pipeline-coverage-target)',
    },
    {
      label: 'booked',
      color: 'var(--bu-color-constant-booked)',
    },
    {
      label: 'lost',
      color: 'var(--bu-color-constant-lost)',
    },
    {
      label: 'total pipeline',
      color: 'var(--bu-color-constant-total-pipeline)',
    },
    {
      label: 'commit',
      color: 'var(--bu-color-constant-commit)',
    },
    {
      label: 'best case',
      color: 'var(--bu-color-constant-best-case)',
    },
    {
      label: 'pipeline',
      color: 'var(--bu-color-constant-pipeline)',
    },
    {
      label: 'pipeline coverage',
      color: 'var(--bu-color-constant-pipeline-coverage)',
    },
    {
      label: 'omitted',
      color: 'var(--bu-color-constant-omitted)',
    },
    {
      label: 'closed won',
      color: 'var(--bu-color-constant-closed-won)',
    },
    {
      label: 'closed lost',
      color: 'var(--bu-color-constant-closed-lost)',
    },
  ];

  static defaultChartColor = 'var(--bu-color-default-chart)';

  static colors = [
    'var(--bu-color-chart-dynamic-primary-blue)',
    'var(--bu-color-chart-dynamic-accent-orange)',
    'var(--bu-color-chart-dynamic-accent-green)',
    'var(--bu-color-chart-dynamic-alert-red)',
    'var(--bu-color-chart-dynamic-highlight-purple)',
    'var(--bu-color-chart-dynamic-warning-yellow)',
    'var(--bu-color-chart-dynamic-success-green)',
    'var(--bu-color-chart-dynamic-special-pink)',
  ];

  /**
   *  The purpose of this function is to ensure that the same input string
   *  (label metric) will always result in the same color output.
   *
   * @param str
   * @returns
   */
  static hash = (s: string) =>
    [...s].reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

  static stringToColour = (
    str: string,
    alreadyChoosedColors: string[] = []
  ) => {
    let idx = Math.abs(
      (HighchartsColors.hash(str) % HighchartsColors.colors.length) - 1
    );

    // Skip the while loop if all colors are already chosen
    if (alreadyChoosedColors.length < HighchartsColors.colors.length) {
      // Check if the color is already chosen
      while (alreadyChoosedColors.includes(HighchartsColors.colors[idx])) {
        idx = (idx + 1) % HighchartsColors.colors.length; // Move to the next color
      }
    }

    return HighchartsColors.colors[idx];
  };

  /**
   *
   * @param label (string) label in the highchart that it will show.
   * @param assign  (bool) in case the label doesn't have a color predefined, you can persist the color assigned to reuse it in another time.
   * @returns (string) a color assigned for the label provided
   */
  static mapColorBasedOnLabel(
    label: string,
    assign = false,
    alreadyChoosedColors: string[] = []
  ) {
    let colorDefined = this.colorsMapped.find(
      (item) => item.label.toLocaleLowerCase() === label.toLocaleLowerCase()
    );

    if (assign && !colorDefined) {
      colorDefined = this.colorAssigned.find(
        (item) => item.label.toLocaleLowerCase() === label.toLocaleLowerCase()
      );
      if (!colorDefined) {
        colorDefined = {
          label,
          color: HighchartsColors.stringToColour(label, alreadyChoosedColors),
        };
        this.colorAssigned.push(colorDefined);
        this.lastAssigned++;
        if (this.lastAssigned >= this.colors.length) {
          this.lastAssigned = 0;
        }
      }
    }
    return colorDefined?.color;
  }
}
