import { GetWidgetDataBody } from 'api/RevBiWidget';
import { Layout } from 'react-grid-layout';
import { Dispatch } from 'redux';

import { getMinSizeForLayout } from 'components/dashboard/Metrics/common/InteractiveGrid/InteractiveGrid.helper';
import {
  InteractiveLayout,
  InteractiveLayoutMinSizeConstraint,
} from 'components/dashboard/Metrics/common/InteractiveGrid/InteractiveGrid.types';
import {
  AnalysisType,
  BUSINESS_TYPES_FIELD_NAMES,
  FORECAST_SUBMISSION,
  MONTH,
  MONTHLY,
  QUARTER,
  QUARTERLY,
  YEAR,
  YEARLY,
} from 'components/dashboard/Metrics/constants';
import {
  BIDashboard,
  BIWidget,
  BIMetricUnion,
  DataDescriptor,
  BIMetricSimple,
  BIReportMetricNewborn,
  BIMetricCreated,
  BIMetricsMap,
} from 'components/dashboard/Metrics/metrics.types';
import { MetricType } from './enums';
import { isBIMetricFormula, isBIMetricSimple } from './Create/utils';
import { SUPPORTED_DRILLDOWN_OBJECTS_WITH_CLICK } from './Widget/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { useContext } from 'react';

export const parseWidget = (widget: BIWidget): BIWidget => {
  return {
    ...widget,
    id: widget.id || widget?._id,
  };
};

export const parseWidgetList = (widgetList: BIWidget[]): BIWidget[] => {
  return widgetList.map(parseWidget);
};

export const parseSaveRedirect = (saveRedirect: string): string => {
  // process the url to make sure it redirect within the same domain
  // first create a url object, then throw away the host from the url object
  // so the redirect is always within boostup
  const location = document.location;
  const url = new URL(saveRedirect, `${location.protocol}\\${location.host}`);
  return `${url.pathname}${url.search}`;
};

export const formatMetricObject = (metricObject: string): string =>
  metricObject
    .split('_')
    .filter((v) => v.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');

export const getDropdownFriendlyName = (column: DataDescriptor): string => {
  if (!column) return '';

  // Special case for Business Type it does not have prefix
  if (BUSINESS_TYPES_FIELD_NAMES.has(column.name)) return column.label;

  const object = column.name.split('.')[0];
  const objectNamePretty = formatMetricObject(object);

  return `${objectNamePretty} - ${column.label}`;
};

const getWidgetsIdsList = (widgetList: BIWidget[]): string[] =>
  widgetList.reduce<string[]>((results, widget) => {
    const id = (widget as BIWidget)?._id;
    if (typeof id === 'string') {
      results.push(id);
    }
    return results;
  }, []);

export const formatDashboardForSave = (dashboard: BIDashboard) => {
  const widgetListIds: string[] = getWidgetsIdsList(
    dashboard.widget_list as BIWidget[]
  );
  const { id, _id, created_at, updated_at, ...saveData } = dashboard;
  saveData.widget_list = widgetListIds;

  return saveData;
};

export const getPeriodPrettyPrint = (period: string): string => {
  switch (period) {
    case MONTH:
      return MONTHLY;
    case QUARTER:
      return QUARTERLY;
    case YEAR:
      return YEARLY;
    default:
      return '';
  }
};

export const getTargetMetricDescription = (
  targetPeriod: string = '',
  targetType: string = ''
): string =>
  targetPeriod && targetType
    ? `${getPeriodPrettyPrint(targetPeriod)} ${targetType} Target`
    : '';

export const getForecastSubmissionDescription = (
  forecastSubmissionMetric: string = '',
  forecastSubmissionType: string = ''
): string =>
  forecastSubmissionMetric && forecastSubmissionType
    ? `${formatMetricObject(
        forecastSubmissionMetric
      )} - ${forecastSubmissionType}`
    : '';

const formatMetricForReportWidget = (
  metric: BIMetricUnion
): BIReportMetricNewborn => ({
  filters: metric.filters,
  name: metric.name,
  metadata: metric.metadata,
  object: (metric as BIMetricSimple).object,
});

export const formatWidgetForSaving = (widget: BIWidget): BIWidget => {
  if (
    widget.metric_list &&
    Array.isArray(widget.metric_list) &&
    typeof widget.metric_list[0] === 'object'
  ) {
    if (widget.analysis_type === AnalysisType.REPORT) {
      return {
        ...widget,
        metric_list: widget.metric_list.map((m) =>
          formatMetricForReportWidget(m)
        ),
      };
    } else {
      return {
        ...widget,
        metric_list: widget.metric_list.map((el) => el._id),
      };
    }
  }
  return widget;
};

export const formatWidgetForUpdating = (widget: BIWidget): BIWidget => {
  if (
    widget.metric_list &&
    Array.isArray(widget.metric_list) &&
    typeof widget.metric_list[0] === 'object' &&
    widget.analysis_type !== AnalysisType.REPORT
  ) {
    // Temporary fix to avoid a 500 error in the BE
    const { pivot_filters, pivot_to_expand, ...payload } =
      widget as GetWidgetDataBody;

    return {
      ...payload,
      metric_list: widget.metric_list.map((el) => el._id),
    };
  }
  return widget;
};

const getSizeRestrictionsForWidgetConfiguration = (
  widgetElement: BIWidget
): Pick<InteractiveLayout, 'maxW' | 'maxH' | 'minSizes'> => {
  // Max widget size
  const maxW = 8;
  const maxH = 8;

  const metricsCount = widgetElement.metric_list?.length || 0;
  const isPieChart = widgetElement.chart_type.includes('pie');
  const hasPivots =
    widgetElement.group_by.length > 0 ||
    widgetElement.analysis_type === AnalysisType.HISTORICAL;

  const isReport = widgetElement.analysis_type === AnalysisType.REPORT;

  let minSizes: InteractiveLayoutMinSizeConstraint[] = [];

  if (isPieChart) {
    minSizes = [{ minW: 2, minH: 3 }];
  } else if (isReport) {
    minSizes = [{ minW: 4, minH: 2 }];
  } else if (!hasPivots) {
    // No Pivots

    if (metricsCount === 1) {
      minSizes = [{ minW: 1, minH: 1 }];
    } else if (metricsCount <= 3) {
      minSizes = [
        { minW: 2, minH: 1 },
        { minW: 1, minH: 2 },
      ];
    } else if (metricsCount === 4) {
      minSizes = [
        { minW: 3, minH: 1 },
        { minW: 1, minH: 2 },
      ];
    } else if (metricsCount === 5) {
      minSizes = [
        { minW: 3, minH: 1 },
        { minW: 2, minH: 2 },
      ];
    } else {
      minSizes = [
        { minW: 4, minH: 1 },
        { minW: 2, minH: 2 },
      ];
    }
  } else {
    // With Pivots
    minSizes = [{ minW: 4, minH: 3 }];
  }

  return { maxW, maxH, minSizes };
};

export const getLayoutForWidget = (
  widgetElement: BIWidget,
  widgetsLayout: Layout[]
): InteractiveLayout => {
  const widgetId = widgetElement._id?.toString() || '';
  const widgetLayout = widgetsLayout.find((layout) => layout.i === widgetId);

  const sizeRestriction =
    getSizeRestrictionsForWidgetConfiguration(widgetElement);

  if (!widgetLayout) {
    const nextFreeRow =
      widgetsLayout.reduce((max, item) => Math.max(max, item.y + item.h), 0) +
      1;
    return {
      i: widgetId,
      x: 0,
      y: nextFreeRow,
      w: sizeRestriction.minSizes[0].minW,
      h: sizeRestriction.minSizes[0].minH,
      ...sizeRestriction,
    };
  }

  const minSize = getMinSizeForLayout(widgetLayout, sizeRestriction.minSizes);
  // Condition in case widget restrictions change and stored width and height
  // are not longer valid
  if (minSize) {
    // If the current item width is less than the minimum width,
    // we update the layout item and placeholder width to match the minimum width.

    if (widgetLayout.w < minSize.minW) {
      widgetLayout.w = minSize.minW;
    }

    // Similarly, if the current item height is less than the minimum height,
    // we update the layout item and placeholder height to match the minimum height.
    if (widgetLayout.h < minSize.minH) {
      widgetLayout.h = minSize.minH;
    }
  }

  return {
    ...widgetLayout,
    ...sizeRestriction,
  };
};

export const parseFilters = (fields: DataDescriptor[]): DataDescriptor[] =>
  fields?.map((el: DataDescriptor) => ({
    ...el,
    label: getDropdownFriendlyName(el),
  }));

export const dispatchIfNotAsked = (
  dispatch: Dispatch<any>,
  action: () => void,
  status: string
) => {
  if (status === 'notAsked') {
    dispatch(action());
  }
};

export const hasMetricAdvancedOptions = (metric: BIMetricSimple): boolean =>
  Boolean(
    (metric?.is_cumulative_sum && metric?.cumulative_sum_period) ||
      metric?.manager_aggregation_type ||
      metric?.forecast_submission_properties?.calculation
  );

export const getWidgetObjects = (
  widget: BIWidget,
  usedFormulaMetricsMap?: BIMetricsMap
): string[] => {
  const objectsSet = new Set<string>();

  if (
    widget.analysis_type === AnalysisType.LIVE ||
    widget.analysis_type === AnalysisType.REPORT
  ) {
    widget.metric_list.forEach((metric: BIMetricCreated) => {
      const metricType = metric.metadata?.type;

      if (metricType === MetricType.Simple) {
        objectsSet.add((metric as BIMetricSimple)?.object);
      } else if (metricType === MetricType.Formula) {
        if (usedFormulaMetricsMap === undefined) {
          console.warn("Trying to return formula metric's object without map");
        } else {
          Object.keys(usedFormulaMetricsMap).forEach((element) => {
            const metricObject = (
              usedFormulaMetricsMap[element] as BIMetricSimple
            ).object;

            if (metricObject) {
              objectsSet.add(metricObject);
            }
          });
        }
      }
    });
  } else if (widget.analysis_type === AnalysisType.HISTORICAL) {
    // Currently historical only supports opportunity
    objectsSet.add('opportunity');
  } else if (widget.analysis_type === AnalysisType.FUNNEL) {
    objectsSet.add('opportunity');
    objectsSet.add('user');
  }

  return Array.from(objectsSet);
};

export const canOpenADrilldown = (
  metric: BIMetricUnion,
  supportedCustomObjects: string[]
) => {
  let hasClickAction = false;

  if (isBIMetricSimple(metric)) {
    const supportedObjects = [
      ...SUPPORTED_DRILLDOWN_OBJECTS_WITH_CLICK,
      ...supportedCustomObjects,
    ];

    // general check
    hasClickAction = supportedObjects.includes(metric.object);
    // if the object is FS, only self_revenue method will have click
    if (FORECAST_SUBMISSION === metric.object) {
      hasClickAction = metric.manager_aggregation_type === 'self_revenue';
    }
  } else if (isBIMetricFormula(metric)) {
    // in the formula modal do we check if the metric is supported.
    hasClickAction = true;
  }

  return hasClickAction;
};
