import { css } from 'emotion';

import { fontCaption3 } from 'assets/css/fontStyles';

export const totalChartTypeDropdown = css`
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
  width: 47px !important;

  height: 100%;

  & > i {
    margin: unset;
  }

  &:hover {
    background-color: var(--bu-primary-200);

    & > i {
      color: var(--bu-primary-500);
    }
  }
`;

export const totalChartTypeIcon = css`
  font-size: 17px;
  color: var(--bu-primary-500);
`;

export const totalChartTypeDropdownIcon = css`
  font-size: 7px;
  margin: unset !important;
`;

export const totalChartTypeDropdownMenu = css`
  display: flex;
  flex-direction: column;
  margin-top: 4px !important;
  z-index: 988 !important;
`;

export const pivotVisualizationLabel = css`
  ${fontCaption3}
  color: var(--bu-gray-700);
  text-transform: uppercase;
  margin: 10px;
`;
