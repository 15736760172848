import {
  HierarchicalWidgetNode,
  WidgetPivotConfiguration,
} from 'api/RevBiWidget';
import { PIVOTS_DELTAS_LOADING_KEY } from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';

import { IRow } from 'components/UI/common/TypedTable/TypedTable';

export interface ExpandableRow extends IRow {
  children?: ExpandableRow[];
  nodePath: string;
  isExpanded: boolean;
  expandValue: string;
  canExpand: boolean;
  nextPivotName: string;
  hierarchyDataRows?: ExpandableRow[];

  /** Flag that determines if have to fetch data,
   * as it can be already be present if the row was previously expanded
   * */
  haveToFetchPivotsIfExpanded: boolean;

  isSubtotal?: boolean;

  /**
   * Flag that determines if the row is just a placeholder
   * for a loading row
   */
  isLoadingRow?: boolean;
}

export const isExpandableRow = (row: IRow): row is ExpandableRow =>
  (row as ExpandableRow).nodePath !== undefined;

export interface PathAwareHierarchicalWidgetNode
  extends HierarchicalWidgetNode {
  nodePath: string;
  pivots?: PathAwareHierarchicalWidgetNode[];
  children?: PathAwareHierarchicalWidgetNode[];
  isSubtotal?: boolean;
}

export type OnExpandClick = (
  nodePath: string,
  /**
   * If true the row was collapsed and should be expanded.
   * if false row was expanded and should be collapsed
   * */
  shouldExpand: boolean,
  haveToFetchPivotsIfExpanded: boolean
) => void;

export const PIVOT_HIERARCHY_SEPARATOR = ' > ';

export const getTreeAsExpandableRows = (
  tree: PathAwareHierarchicalWidgetNode[],
  pivots: WidgetPivotConfiguration[],
  deltasByPath: Record<string, HierarchicalWidgetNode>,
  expandedNodePaths: string[]
): ExpandableRow[] =>
  tree.map((node) => {
    const nodePath = node.nodePath;

    const isLoadingNode = node.isLoadingNode;
    if (isLoadingNode) {
      // Dummy object to show the loading state as a row
      return {
        id: nodePath,
        nodePath,
        isLoadingRow: true,
        isExpanded: false,
        expandValue: '',
        canExpand: false,
        nextPivotName: '',
        haveToFetchPivotsIfExpanded: false,
      };
    }

    const isPivotExpanded = expandedNodePaths.some((path) =>
      path.startsWith(nodePath)
    );
    const nodePathSegments = nodePath.split(PIVOT_HIERARCHY_SEPARATOR);
    const expandValue = nodePathSegments.pop()!;
    const nodePathLevel = nodePathSegments.length;
    let nextPivotExpandible = '';

    const thereArePivotsAlreadyFetched = Boolean(
      node.pivots && node.pivots.length > 0
    );
    const canExpand =
      !!node.pivot_is_expandible || thereArePivotsAlreadyFetched;

    if (canExpand) {
      nextPivotExpandible = pivots[nodePathLevel + 1].display_name;
    }

    const deltasLoadingPath = [
      ...nodePathSegments,
      PIVOTS_DELTAS_LOADING_KEY,
    ].join(PIVOT_HIERARCHY_SEPARATOR);

    const isDeltasLoading = deltasByPath[deltasLoadingPath]?.isLoading;

    const metricDeltas = isDeltasLoading
      ? {
          isLoading: true,
        }
      : deltasByPath[nodePath];

    return {
      ...node,
      id: nodePath,
      // Removing categories from the node to avoid duplicating the nodes in the response
      pivots: undefined,
      isExpanded: isPivotExpanded,
      metricDeltas,
      expandValue: expandValue,
      canExpand: canExpand,
      haveToFetchPivotsIfExpanded: canExpand && !thereArePivotsAlreadyFetched,
      nextPivotName: nextPivotExpandible,
      children: node.children
        ? getTreeAsExpandableRows(
            node.children,
            pivots,
            deltasByPath,
            expandedNodePaths
          )
        : [],
      hierarchyDataRows:
        isPivotExpanded && node.pivots
          ? getTreeAsExpandableRows(
              node.pivots,
              pivots,
              deltasByPath,
              expandedNodePaths
            )
          : undefined,
    };
  });

export const expandFirstLevelChildren = (row: ExpandableRow) => ({
  ...row,
  isChildrenVisible: row.children && row.children.length > 0,
});
