import {
  WidgetColumnConfiguration,
  WidgetPivotConfiguration,
} from 'api/RevBiWidget';
import { VisualizationType } from '../../enums';

export interface RevBiCustomPoint {
  /**
   * This is needed when using pie charts as it doesn't support negative values
   * But we still want to format value as negative
   */
  isNegative?: boolean;
  pivot1Value: string;
  /**
   * Pivot 2 can be on the point level
   * if its a stacked chart with username as second pivot
   * So with drilldown
   */
  pivot2Value?: string;
}

export interface RevBiCustomSeries {
  /**
   * This is needed to format values correctly
   */
  valueType: string;
  metricId: string;
  pivot2Value?: string;
}

/**
 * METRIC CHART CONFIGURATION
 */

export interface MetricChartConfiguration extends WidgetColumnConfiguration {
  chartType: VisualizationType;
  yAxis?: number;
}

/**
 * PIVOT CHART CONFIGURATION
 */

export interface ChartPivotConfiguration extends WidgetPivotConfiguration {
  isManagerHierarchy: boolean;
}

/**
 * SIMPLE CHART: COLUMN - LINES
 */

export interface SimpleMetricChartConfiguration
  extends MetricChartConfiguration {
  chartType:
    | VisualizationType.Column
    | VisualizationType.Line
    | VisualizationType.Bar;
}

export const isSimpleVisualizationChart = (
  metric: MetricChartConfiguration
): metric is SimpleMetricChartConfiguration =>
  metric.chartType === VisualizationType.Column ||
  metric.chartType === VisualizationType.Line ||
  metric.chartType === VisualizationType.Bar;

/**
 * STACKED CHART: COLUMN - LINES
 */

export interface StackedMetricChartConfiguration
  extends MetricChartConfiguration {
  chartType: VisualizationType.ColumnStacked | VisualizationType.BarStacked;
}

export const isStackedVisualizationChart = (
  metric: MetricChartConfiguration
): metric is StackedMetricChartConfiguration =>
  metric.chartType === VisualizationType.ColumnStacked ||
  metric.chartType === VisualizationType.BarStacked;

/**
 * PIE CHART
 */

export interface PieChartConfiguration extends MetricChartConfiguration {
  chartType: VisualizationType.Pie;
}

export const isPieChart = (
  metric: MetricChartConfiguration
): metric is PieChartConfiguration =>
  metric.chartType === VisualizationType.Pie;

/**
 * CLICK EVENT RELATED
 */

export interface ChartClickedData {
  metricId: string;
  pivotValues: [string, string | undefined];
  y: number;
}

export type OnChartDataClick = (pointEvent: ChartClickedData) => void;
