import React, { useContext } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import BuToggle from 'components/UI/BuToggle';
import { WidgetActions } from 'components/dashboard/Metrics/Widget/Header/Actions/WidgetActions';
import {
  DEFAULT_TITLE,
  PREVIEW_TITLE,
} from 'components/dashboard/Metrics/Widget/Header/constants';
import {
  WidgetTitleRow,
  ActionsWrapper,
  TitleWrapper,
  MetricsToggleLabel,
  cacheLabel,
  refreshButton,
  titleSection,
  titleRow,
  refreshIcon,
  noTextOverflow,
} from 'components/dashboard/Metrics/Widget/Header/styles';
import { UNTITLED_DASHBOARD } from 'components/dashboard/Metrics/constants';
import { RevBISettingsContext } from '../../contexts/RevBISettingsContext';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { ExclamationIcon } from 'components/dashboard/Metrics/metrics.styles';
import { useRefreshData } from 'components/hooks/useRefreshData';
import classNames from 'classnames';
import Tooltip from 'components/UI/common/Tooltip';
import { Popup } from 'semantic-ui-react';

export interface RefreshButtonConfiguration {
  showRefreshButton: boolean;
  refreshButtonLoading: boolean;
  disabled: boolean;
  cacheDate?: string;
  targetDate?: number;
  onRefreshData?: () => void;
}
interface Props {
  id?: string;
  name?: string;
  dashboardName?: string;
  tooltip?: JSX.Element;
  isCreateEditMetric?: boolean;
  isDashboardWidget?: boolean;
  isDashboardModal?: boolean;
  isReportViewWidget?: boolean;
  showMetrics?: boolean;
  optionalMetrics?: boolean;
  alternativeVisibility: boolean;
  showExpandButton?: boolean;
  onEditWidget?: (metricId: string) => void;
  onCloneWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onTitleClicked?: () => void;
  onCloseWidgetModal?: () => void;
  setShowMetrics?: (checked: boolean) => void;
  refreshButtonConfig?: RefreshButtonConfiguration;
}

export const WidgetHeader: React.FC<Props> = ({
  id,
  name,
  dashboardName = '',
  tooltip,
  isCreateEditMetric = false,
  isDashboardWidget = false,
  isDashboardModal = false,
  isReportViewWidget = false,
  showMetrics = false,
  optionalMetrics = false,
  refreshButtonConfig = {
    showRefreshButton: false,
    refreshButtonLoading: false,
    disabled: false,
    cacheDate: '',
    targetDate: 0,
    onRefreshData: () => {},
  },
  showExpandButton = true,
  alternativeVisibility = false,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
  onTitleClicked,
  onCloseWidgetModal,
  setShowMetrics = () => {},
}) => {
  const { warningElapsedUpdateTimeSeconds } = useContext(RevBISettingsContext);

  const refreshData = useRefreshData({
    cacheDate: refreshButtonConfig.cacheDate ?? '',
    onClick: refreshButtonConfig.onRefreshData ?? (() => {}),
    isLoading: refreshButtonConfig.refreshButtonLoading,
    testingLabel: 'widget',
    warningElapsedTimeSeconds: warningElapsedUpdateTimeSeconds,
    disabled: refreshButtonConfig.disabled,
    targetDate: refreshButtonConfig.targetDate,
  });

  const nameBuilder = () => {
    let currentName;

    if (isCreateEditMetric) {
      currentName = PREVIEW_TITLE;
    } else if (isDashboardModal) {
      currentName = (
        <>
          <span>{`${dashboardName || UNTITLED_DASHBOARD} / `}</span> {name}
        </>
      );
    } else if (name) {
      currentName = name;
    } else {
      currentName = DEFAULT_TITLE;
    }

    return currentName;
  };

  const widgetName = nameBuilder();

  return (
    <WidgetTitleRow
      isDashboardModal={isDashboardModal}
      isDashboardWidget={isDashboardWidget}
    >
      <TitleWrapper>
        {alternativeVisibility && (
          <h5>
            alternative view
            <TooltipWrapper
              tooltip={
                <p>
                  This widget is configured using the alternative team
                  visibility feature. This is not your team hierarchy. Any
                  user-based filters in this page will not work on this widget.
                </p>
              }
              position="right center"
            >
              <ExclamationIcon>
                <BuIcon
                  name={BoostUpIcons.BadgeInfoOutline}
                  color="var(--bu-gray-700)"
                />
              </ExclamationIcon>
            </TooltipWrapper>
          </h5>
        )}
        <section className={titleSection}>
          <div className={titleRow}>
            <h4 data-testing="widget-title" className={noTextOverflow}>
              <Tooltip tooltip={widgetName} position="top center" hoverable>
                {widgetName}
              </Tooltip>
            </h4>

            {refreshButtonConfig.showRefreshButton && (
              <Popup
                trigger={
                  <button
                    onClick={refreshData.buttonProps.onClick}
                    className={classNames(refreshButton, {
                      load: refreshData.isLoading,
                    })}
                    type="button"
                    data-testing={refreshData.buttonProps['data-testing']}
                    disabled={refreshData.buttonProps.disabled}
                  >
                    <BuIcon
                      className={refreshIcon}
                      name={BoostUpIcons.RefreshIcon}
                      color="var(--bu-primary-700)"
                    />
                  </button>
                }
                position="bottom center"
              >
                {refreshButtonConfig.showRefreshButton &&
                  refreshData.lastUpdatedText &&
                  !refreshData.showCountdown && (
                    <p
                      className={classNames(cacheLabel, noTextOverflow, {
                        redAlert: refreshData.showWarning,
                      })}
                      data-testing={refreshData.lastUpdatedTestingKey}
                    >
                      {refreshData.lastUpdatedText}
                    </p>
                  )}
                {refreshButtonConfig.showRefreshButton &&
                  refreshData.showCountdown && (
                    <p className={classNames(cacheLabel, noTextOverflow)}>
                      {refreshData.countdownLabel}
                    </p>
                  )}
              </Popup>
            )}
          </div>
        </section>
      </TitleWrapper>

      <ActionsWrapper>
        {optionalMetrics && (
          <BuToggle
            checked={showMetrics}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setShowMetrics(e.target.checked)
            }
            leftLabel
          >
            <MetricsToggleLabel>Metrics</MetricsToggleLabel>
          </BuToggle>
        )}
        {isDashboardWidget && !isDashboardModal && (
          <WidgetActions
            isReportViewWidget={isReportViewWidget}
            widgetId={id}
            tooltip={tooltip}
            onEditWidget={onEditWidget}
            onCloneWidget={onCloneWidget}
            onRemoveWidget={onRemoveWidget}
            data-testing="widget-actions"
          />
        )}
        {showExpandButton &&
          (!isDashboardModal ? (
            <BuButton borderless onClick={onTitleClicked} icon>
              <BuIcon
                name={BoostUpIcons.FullScreen}
                data-testing="widget-full-screen"
              />
            </BuButton>
          ) : (
            <BuButton
              borderless
              secondary
              onClick={onCloseWidgetModal}
              testingLabel="close-modal"
            >
              <BuIcon color="black" name={BoostUpIcons.ClosePopup} />
            </BuButton>
          ))}
      </ActionsWrapper>
    </WidgetTitleRow>
  );
};
