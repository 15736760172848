import { TooltipContent } from 'components/dashboard/Metrics/common/MetricTooltipContent/TooltipContent';

import { Popup } from 'semantic-ui-react';
import {
  OnVisualizationChange,
  VisualizationDropdown,
} from './Dropdown/VisualizationDropdown';
import { ErrorTotal } from './Error/ErrorTotal';
import { TotalMetric } from './helper';
import {
  containerTileWithoutValue,
  metricNameParagraph,
  totalItemContainer,
  totalItemPopup,
  valueStyle,
} from './styles';

interface TotalSectionItemProps {
  total: TotalMetric;
  popupPosition: 'bottom left' | 'bottom center';
  containerStyle: string;
  titleStyle: string;

  onVisualizationChange: OnVisualizationChange;
}

const TotalSectionItem = ({
  total,
  onVisualizationChange,
  popupPosition,
  containerStyle,
  titleStyle,
}: TotalSectionItemProps) => {
  const {
    id,
    shouldHideValue,
    totalTitle,
    displayValue,
    error,
    visualization,
    tooltipConfig,
  } = total;

  return (
    <>
      {error ? (
        <ErrorTotal
          name={totalTitle}
          errorMessage={error}
          containerStyle={containerStyle}
          titleStyle={titleStyle}
        />
      ) : (
        <>
          <Popup
            key={`total-${id}`}
            position={popupPosition}
            className={totalItemPopup}
            content={
              <TooltipContent
                metric={tooltipConfig.metric}
                total={tooltipConfig.total}
                isCountAgg={tooltipConfig.isCountAgg}
                prefix={tooltipConfig.prefix}
                sufix={tooltipConfig.sufix}
                totalType={tooltipConfig.totalType}
                widgetMetricsDisplayName={
                  tooltipConfig.widgetMetricsDisplayName
                }
              />
            }
            trigger={
              shouldHideValue ? (
                <div className={containerTileWithoutValue}>
                  <p
                    data-testing="historical-total-title"
                    className={metricNameParagraph}
                  >
                    {totalTitle}
                  </p>
                </div>
              ) : (
                <div className={totalItemContainer}>
                  <span data-testing="total-value" className={valueStyle}>
                    {displayValue}
                  </span>
                  <p data-testing="total-title" className={titleStyle}>
                    {totalTitle}
                  </p>
                </div>
              )
            }
          />
        </>
      )}
      {visualization && (
        <VisualizationDropdown
          visualization={visualization}
          metricId={id}
          onVisualizationChange={onVisualizationChange}
        />
      )}
    </>
  );
};

export default TotalSectionItem;
